import { DrupalParagraph} from "next-drupal"
import {processHTML} from "../../lib/utils";
import classNames from "classnames";
import config from "site.config";

interface ParagraphHtmlProps {
    paragraph: DrupalParagraph
}

export function ParagraphSectionTitle({ paragraph, ...props }: ParagraphHtmlProps) {
    if( config.debug) {
        console.log('ParagraphSectionTitle');
        console.log(paragraph)
    }
    return (
        <div key={paragraph.id} className={classNames("section_title", paragraph.type)}>
            <h2>{paragraph.field_section_title}</h2>
            <div className="divider"></div>
        </div>
    )
}
